import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
  MODEL,
  CLIENT,
} from '../../util/types';

import { updatetransacationmetadata } from '../../util/api.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  ResponsiveImage,
  H2,
  PrimaryButton,
  Button,
  IconCard,
  Modal,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';
import Slider from 'react-slick';
import {
  eyeOptions,
  genderOptions,
  hairTypeOptions,
  listingTypes,
} from '../../config/configListing.js';
import { getUserType } from '../../util/helper.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isApplyJobModal, setIsApplyJobModal] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [expired, setExpired] = useState(false);
  const [isEnquired, setIsEnquired] = useState(false); const [popModalOpen, setPopModalOpen] = useState(false);

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    publishedListings,
  } = props;

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    cssEase: 'linear',

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));
  const images = currentListing?.images;
  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const {
    ageRange,
    age,
    gender_multi_select,
    ethnicity,
    hair,
    eye,
    height,
    shoes,
    bustChest,
    castingDescription,
    waist,
    hips,
    isCollaboration,
    jobInformation,
    jobType,
    listingType,
    clientName,
    castingEndDate,
    location: userLocation,
    expHours,
    priceType,
  } = publicData || {};

  const modalGender = publicData.gender;
  const userType = getUserType(currentUser);
  const isAlreadyEnquired =
    currentListing &&
    Array.isArray(currentListing?.attributes?.publicData?.isEnquiry) &&
    currentListing?.attributes?.publicData?.isEnquiry.findIndex(
      st => st == currentUser?.id?.uuid
    ) >= 0;

  const currentTime = new Date().getTime();
  const castingTime = new Date(castingEndDate).getTime();

  const newCastingEndDate = new Date(castingEndDate).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const formattedAge = Array.isArray(ageRange) && ageRange.join('-');
  const gender = genderOptions
    .filter(data => gender_multi_select?.includes(data?.key))
    .map(data => data?.label)
    .join(',');

  const eyeLabel = eyeOptions.reduce((label, item) => {
    if (item.key.includes(eye)) {
      return item.label;
    }
    return label;
  }, '');

  const hairLabel = hairTypeOptions.reduce((label, item) => {
    if (item.key.includes(hair)) {
      return item.label;
    }
    return label;
  }, '');

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const appliedModelJobText = intl.formatMessage({ id: 'listingPage.appliedModelJobText' });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };
  const inquiryDetails = {
    inquiryPrice: price?.amount / 100,
    appliedClientMessage: `Applied for: ${title}`,
    appliedModelMessage: appliedModelJobText,
  };
  const message = ` Applied for: ${title} `;

  const modelListingId = currentUser?.attributes?.profile?.publicData?.listingId || {};
  const modelUserId = currentUser?.id?.uuid || {};
  const clientUserId = currentListing?.author?.id?.uuid || {};

  // const { title } = urrentListing?.attributes || {};
  // const listingTitle=currentListing.

  const handleApplyJob = () => {
    setUpdateInProgress(true);
    onSendInquiry(currentListing, message, inquiryDetails)
      .then(resp => {
        const { uuid } = resp || {};
        const { title } = currentListing?.attributes || {};
        const listingId = currentListing?.id?.uuid || {};
        // { Transicationuuid: uuid, ModelUserId: ModelUserId, clientListingId: clientListingId, modelListingId: modelListingId }
        return updatetransacationmetadata({ transactionId: uuid, listingTitle: title, modelListingId, modelUserId, clientUserId, listingId })
      })
      .then((res) => {
        setIsApplyJobModal(appliedModelJobText);
        setUpdateInProgress(false);
      })
      .catch(() => {
        setUpdateInProgress(false);
      });

  };

  const submitInProgress = updateInProgress;

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );

  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  const hasImages = currentListing.images && currentListing.images.length > 0;
  const firstImage = hasImages ? currentListing.images[0] : null;
  const { variantPrefix = 'scaled-medium' } = config.listing;
  const variants = firstImage ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix)) : [];

  const shouldShowSlider = currentListing?.images?.length > 3;
  // const slides = images.map((image, index) => ({
  //   src: image.original,
  //   alt: `Image ${index + 1}`,
  // }));

  const openLightbox = index => {
    setSelectedImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const nextImage = () => {
    setSelectedImageIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setSelectedImageIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const isSubscriptionActive = currentUser?.attributes?.profile?.protectedData?.currentlyActive
    ? true
    : false;

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                listingType={listingType}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null}
            {/* <H4 as="h1" className={css.orderPanelTitle}>
              <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
            </H4> */}
            {/* <SectionGallery
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            /> */}
            {listingType == MODEL ? (
              <div className={css.mainContentWrapper}>
                <div className={css.modelProfileDetails}>
                  <div className={css.modelProfileImg}>
                    {' '}
                    <div className={css.bannerImage}>
                      <ResponsiveImage
                        alt={title}
                        image={currentAuthor?.profileImage}
                        variants={variants}
                      />
                    </div>
                  </div>
                  <div className={css.modelProfileInfo}>
                    {' '}
                    <div className={css.personalDetails}>
                      <div className={css.profileName}>{title}</div>
                      {userLocation?.address ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingPage.address" /> :
                          </H2>
                          {userLocation?.address}
                        </div>
                      ) : null}
                      {jobInformation ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingPage.jobInformation" /> :
                          </H2>
                          {jobInformation}
                        </div>
                      ) : null}
                      {castingEndDate ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.castingEndDate" /> :
                          </H2>
                          {newCastingEndDate}
                        </div>
                      ) : null}
                      {age ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.age" /> :
                          </H2>
                          {age}
                        </div>
                      ) : null}

                      {ageRange ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.age" /> :
                          </H2>
                          {formattedAge}
                        </div>
                      ) : null}

                      {gender_multi_select ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.genderPlaceholder" /> :
                          </H2>
                          {gender}
                        </div>
                      ) : null}

                      {hair ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.hair" /> :
                          </H2>
                          {hairLabel}
                        </div>
                      ) : null}

                      {eye ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.eye" /> :
                          </H2>
                          {eyeLabel}
                        </div>
                      ) : null}

                      {shoes ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.shoes" /> :
                          </H2>
                          {shoes / 100}
                        </div>
                      ) : null}

                      {waist ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.waist" /> :
                          </H2>
                          {waist / 100}
                        </div>
                      ) : null}

                      {bustChest ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.bust/chest" /> :
                          </H2>
                          {bustChest / 100}
                        </div>
                      ) : null}

                      {height ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.heightLabel" /> :
                          </H2>
                          {height / 100}
                        </div>
                      ) : null}
                      {hips ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.hips" /> :
                          </H2>
                          {hips / 100}
                        </div>
                      ) : null}

                      {isCollaboration ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            *{' '}
                            <FormattedMessage id="EditListingPriceAndStockForm.isCollaborationLabel" />
                          </H2>
                        </div>
                      ) : null}

                      {clientName ? (
                        <div className={css.infoBlock}>
                          <H2 className={css.label}>
                            <FormattedMessage id="EditListingDetailsForm.clientName" /> :
                          </H2>
                          {clientName}
                        </div>
                      ) : null}

                      {userType === CLIENT ? (
                        <div className={css.infoBlock}>
                          <PrimaryButton
                            onClick={() => setInquiryModalOpen(true)}
                            className={css.sendBtn}
                          >
                            Send Message to {title}
                          </PrimaryButton>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={css.modelHireType}>
                <div className={css.topBarHeading}>
                  <div className={css.headingName}>{title}</div>

                  {/* <div  onClick={()=>setInquiryModalOpen(true)} className={css.applyButton}>
                   <Button disabled ={userType === CLIENT} ><FormattedMessage id ="EditListingPage.apply"/></Button>
                 </div> */}
                  {userType === MODEL ? (
                    <div className={css.applyButton}>
                      {castingTime > currentTime ? (
                        <Button
                          onClick={() => {
                            isSubscriptionActive == true ? handleApplyJob() : setPopModalOpen(true);
                          }}
                          inProgress={submitInProgress}
                          disabled={isAlreadyEnquired}
                        >
                          {isAlreadyEnquired ? (
                            <FormattedMessage id="EditListingPage.alreadyApply" />
                          ) : (
                            <FormattedMessage id="EditListingPage.apply" />
                          )}
                        </Button>
                      ) : (
                        <Button disabled inProgress={submitInProgress}>
                          <FormattedMessage id="EditListingPage.expired" />
                        </Button>
                      )}
                    </div>
                  ) : null}
                </div>
                <div className={css.hireData}>
                  <div className={css.listLine}>
                    {userLocation?.address ? (
                      <div className={css.locationLine}>
                        <span className={css.iconList}>
                          <IconCard brand="location" />
                        </span>
                        <span className={css.iconName}>{userLocation?.address}</span>
                      </div>
                    ) : (
                      <div className={css.locationLine}>
                        <span className={css.iconList}>
                          <IconCard brand="location" />
                        </span>
                        {jobType == 'jobType' ? (
                          <span className={css.iconName}>{userLocation?.address}</span>
                        ) : (
                          <span className={css.iconName}>
                            <FormattedMessage id="EditListingPage.onlineJob" />
                          </span>
                        )}
                      </div>
                    )}
                    {castingEndDate ? (
                      <div className={css.locationLine}>
                        <span className={css.iconList}>
                          <IconCard brand="time" />
                        </span>
                        <span className={css.iconName}>
                          <FormattedMessage id="EditListingPage.castingEnds" /> {newCastingEndDate}
                        </span>
                      </div>
                    ) : null}
                    {price?.amount ? (
                      <div className={css.locationLine}>
                        <span className={css.iconList}>
                          <IconCard brand="dollar" />
                        </span>
                        <span className={css.iconName}>
                          <FormattedMessage id="EditListingPage.paid" />
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={css.requirementPayment}>
                  <div className={css.requirementBox}>
                    <div className={css.requirementHeading}>
                      {' '}
                      <FormattedMessage id="EditListingPage.requirements" />
                    </div>
                    <div className={css.paymentCard}>
                      <span>
                        <FormattedMessage id="EditListingDetailsForm.ageLabel" /> :{' '}
                      </span>
                      <span>{formattedAge}</span>
                    </div>
                    <div className={css.paymentCard}>
                      <span>
                        {' '}
                        <FormattedMessage id="EditListingModelPreferences.genderLabel" />:{' '}
                      </span>
                      <span>{gender}</span>
                    </div>
                    <div className={css.paymentCard}>
                      <span> Price Type: </span>
                      <span>{priceType?.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('-')}</span>
                    </div>
                    <div className={css.paymentCard}>
                      <span> Expected Hours: </span>
                      <span>{expHours}</span>
                    </div>
                  </div>
                  <div className={css.requirementBox}>
                    <div className={css.requirementHeading}><FormattedMessage id="EditListingPage.payment" /></div>
                    {price?.amount ?
                      <div className={css.paymentCard}>
                        <span><FormattedMessage id="EditListingPage.rate" /> </span>
                        <span>{formattedPrice}</span>
                      </div>
                      :
                      <div className={css.paymentCard}><FormattedMessage id="EditListingPage.collaboration" /></div>
                    }
                  </div>
                </div>
                <div className={css.jobDescription}>
                  <div className={css.descriptionTitle}>
                    <FormattedMessage id="EditListingPage.description" />
                  </div>
                  <div className={css.descriptionBox}>
                    {castingDescription &&
                      <pre>
                        {castingDescription}
                      </pre>
                    }
                  </div>

                </div>
              </div>
            )}
            <div className={css.moodboardSlider}>
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage id="EditListingPage.portfolio" />
              </H4>
              <div className={css.portfolioImages}>
                {shouldShowSlider ? (
                  <div className={css.portfolioGrid}>
                    {/* <Slider {...settings}> */}
                    {currentListing?.images?.map((image, index) => {
                      return (
                        <div key={index} className={css.sliderImagesss}>
                          <ResponsiveImage
                            alt={'moodboard images'}
                            image={image}
                            className="gallery-image"
                            variants={variants}
                            onClick={() => openLightbox(index)}
                          />
                        </div>
                      );
                    })}
                    {/* </Slider> */}
                    {lightboxOpen && (
                      <div className={css.lightbox}>
                        <button onClick={closeLightbox} className={css.closeButton}>
                          <IconCard brand="close" />
                        </button>
                        <div onClick={prevImage} className={css.navButtonLeft}>
                          <IconCard brand="preview" />
                        </div>
                        <ResponsiveImage
                          alt={'moodboard images'}
                          image={images[selectedImageIndex]}
                          variants={variants}
                          className={css.galleryImage}
                        />
                        <div onClick={nextImage} className={css.navButtonRight}>
                          <IconCard brand="next" />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={css.imageGrid}>
                    {images?.map((image, index) => (
                      <div key={index} className={css.gridImage}>
                        <ResponsiveImage
                          alt={'moodboard images'}
                          image={image}
                          variants={variants}
                          className="gallery-image"
                          onClick={() => openLightbox(index)}
                        />
                      </div>
                    ))}
                    {lightboxOpen && (
                      <div className={css.lightbox}>
                        <button onClick={closeLightbox} className={css.closeButton}>
                          <IconCard brand="close" />
                        </button>
                        <div onClick={prevImage} className={css.navButtonLeft}>
                          <IconCard brand="preview" />
                        </div>
                        <ResponsiveImage
                          alt={'moodboard images'}
                          image={images[selectedImageIndex]}
                          variants={variants}
                          className={css.galleryImage}
                        />
                        <div onClick={nextImage} className={css.navButtonRight}>
                          <IconCard brand="next" />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <SectionTextMaybe text={description} showAsIngress />
            {/* <SectionDetailsMaybe
              publicData={publicData}
              metadata={metadata}
              listingConfig={listingConfig}
              intl={intl}
            /> */}
            {/* {listingConfig.listingFields.reduce((pickedElements, config) => {
              const { key, enumOptions, includeForListingTypes, scope = 'public' } = config;
              const listingType = publicData?.listingType;
              const isTargetListingType =
                includeForListingTypes == null || includeForListingTypes.includes(listingType);

              const value =
                scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
              const hasValue = value != null;
              return isTargetListingType && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                ? [
                    ...pickedElements,
                    <SectionMultiEnumMaybe
                      key={key}
                      heading={config?.showConfig?.label}
                      options={createFilterOptions(enumOptions)}
                      selectedOptions={value || []}
                    />,
                  ]
                : isTargetListingType && hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                ? [
                    ...pickedElements,
                    <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                  ]
                : pickedElements;
            }, [])} */}
            {/* <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            /> */}
            <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
            <SectionAuthorMaybe
              title={title}
              history={history}
              isListingPage={true}
              jobInformation={jobInformation}
              userType={userType}
              isCollaboration={isCollaboration}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              publishedListings={publishedListings}
              listingType={listingType}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
              modalGender={modalGender}
              height={height}
              isAlreadyEnquired={isAlreadyEnquired}
            />
          </div>
        </div>
      </LayoutSingleColumn>

      <Modal
        id="ListingPage.Appyjob"
        contentClassName={css.inquiryModaldetails}
        isOpen={isApplyJobModal}
        onClose={() => setIsApplyJobModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div>{isApplyJobModal}</div>
        <NamedLink name="SearchClientPage">Explore More Opportunities</NamedLink>
      </Modal>

      <Modal
        id="ListingPage.purchaseSubscription"
        isOpen={popModalOpen}
        usePortal
        onClose={() => setPopModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div>
          <p>Unlock Modeling Opportunities!</p>
          <p>
            To apply to modeling jobs, you need an active subscription. Click the button below to
            subscribe and start applying to modeling jobs.
          </p>
          <NamedLink name="StripeSubscriptionPage">Go to subscription Page</NamedLink>
        </div>
      </Modal>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser, currentUserListing } = state.user;
  const publishedListings =
    Array.isArray(currentUserListing) &&
    currentUserListing
      ?.filter(listing => listing.attributes.state === 'published')
      .map(listing => ({
        title: listing.attributes.title,
        jobInformation: listing.attributes.publicData.jobInformation,
        listingId: listing.id.uuid,
        heightRange: listing.attributes.publicData.heightRange,
        clientGender: listing.attributes.publicData.gender_multi_select,
      }));

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };
  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    publishedListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message, inquiryDetails) =>
    dispatch(sendInquiry(listing, message, inquiryDetails)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
